import React, { useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const Header = ({ logout }) => {
  const [openModal, setOpenModal] = useState(false);

  const logoutHandler = async () => {
    const its = JSON.parse(sessionStorage.getItem("ITS_ID"));
    const responseSession = await axios.post("/UpdateLogin.php", { Id: its });
    if (responseSession.data.status === "Valid") {
      sessionStorage.removeItem("ITS_ID");
      sessionStorage.removeItem("ID");
      logout(true);
    }
  };

  const handleLogout = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleConfirmLogout = () => {
    setOpenModal(false);
    logoutHandler();
  };

  return (
    <div>
      <nav className="navbar navbar-expand navbar-light static-top sticky-top">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <a className="navbar-brand mr-1" href="#">
          <img
            className="img-fluid"
            alt=""
            src="/Assets/img/Logo_Bhopal_Jamaat.png"
            width="30%"
            height="50px"
            style={{ filter: "brightness(200%) contrast(250%)" }}
          />
        </a>
        <form className="d-none d-md-inline-block form-inline ml-auto mr-0 mr-md-5 my-2 my-md-0 osahan-navbar-search"></form>
        <ul className="navbar-nav ml-auto ml-md-0 osahan-right-navbar">
          <li className="nav-item dropdown no-arrow osahan-right-navbar-user">
            <Button
              className="dropdown-item"
              onClick={handleLogout}
              style={{
                fontWeight: "bold",
                backgroundColor: "white",
                color: "red",
                border: "1px solid red",
                borderRadius: "10px", // Optional: add border radius for rounded corners
              }}
            >
              <i className="fas fa-sign-out-alt"></i> &nbsp; Logout
            </Button>
          </li>
        </ul>
      </nav>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="logout-modal-title"
        aria-describedby="logout-modal-description"
      >
        <Box sx={{ ...modalStyle, width: 300 }}>
          <Typography id="logout-modal-title" variant="h6" component="h2">
            Are you sure?
          </Typography>
          <Typography id="logout-modal-description" sx={{ mt: 2 }}>
            Logout below if you are ready to exit from the application.
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Button onClick={handleCloseModal} variant="contained">
              Cancel
            </Button>
            <Button
              onClick={handleConfirmLogout}
              variant="contained"
              color="error"
            >
              Logout
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default Header;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
