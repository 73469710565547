import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

export default function Events(props) {
  const [events, setEvents] = useState(null);
  const [count, setCount] = useState(null);
  const [userDetail, setUserDetails] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post("/Events.php");
        setEvents(response.data.phpresult);
        setCount(response.data.Count);

        const its = JSON.parse(sessionStorage.getItem("ITS_ID"));
        const response1 = await axios.post("/UserDetails.php", { ITS: its });
        setUserDetails(response1.data.phpresult);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <div id="wrapper">
        <div id="content-wrapper">
          <div className="container-fluid pb-0">
            <div className="top-category section-padding mb-4">
              <div className="row">
                <div className="col-md-12">
                  <div className="main-title">
                    <div
                      className="btn-group float-right right-action"
                      style={{ color: "white", fontWeight: "bolder" }}
                    >
                      Name: {userDetail ? userDetail["Name"] : ""}
                      <br />
                      Area/Jamaat: {userDetail ? userDetail["Area"] : ""}
                      <br />
                      {/* IP Address: {userDetail ? userDetail["IP"] : ""} */}
                    </div>
                    <h6 style={{ color: "white" }}>Haidery Masjid - Bhopal</h6>
                  </div>
                </div>
              </div>
            </div>
            {/* <hr /> */}
            <div className="video-block section-padding">
              <div className="row">
                <div className="col-md-12">
                  <div className="main-title">
                    {count > 0 ? (
                      <h6 style={{ color: "white" }}>Active Events</h6>
                    ) : (
                      <h6 style={{ color: "white" }}>
                        Currently No live Events Found !!
                      </h6>
                    )}
                  </div>
                </div>

                {count > 0 &&
                  events &&
                  events.map((result, index) => (
                    <div className="col-xl-4 col-sm-6 mb-3" key={index + 1}>
                      <div className="channels-card">
                        <div className="channels-card-body">
                          <div className="channels-title">
                            <span
                              style={{
                                fontSize: "24px",
                                fontWeight: "bold",
                                color: "red",
                              }}
                            >
                              {result.Name}
                            </span>
                          </div>
                          <div className="channels-view">
                            <span
                              style={{
                                fontSize: "15px",
                                fontWeight: "bold",
                                color: "GrayText",
                              }}
                            >
                              {result.Details}
                            </span>
                          </div>
                          <br />
                          <div className="channels-card-image">
                            <Link
                              to={`/SingleVideo?EventId=${result.Id}&VideoType=${result.Type}`}
                            >
                              <div className="channels-card-image-btn">
                                <button
                                  type="button"
                                  className="btn btn-outline-danger"
                                  style={{ width: "80%", padding: "10px" }}
                                >
                                  Click here to Watch
                                </button>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
