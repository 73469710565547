import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Button from "@mui/material/Button"; // Import Button component
import axios from "axios";

export default function Login({ loggedIn, status }) {
  const [id, setId] = useState("");
  const [authenticating, setAuthenticating] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarButton, setSnackbarButton] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const navigate = useNavigate();

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      // Set authenticating status
      setAuthenticating(true);

      var ip = "NA";
      // await axios.get("https://geolocation-db.com/json/").then((res) => {
      //   ip = res.data.IPv4;
      // });

      const its = event.target.ITS.value;
      const sendData = {
        Id: its,
        Ip: ip,
      };
      const response = await axios.post("/Login.php", sendData);
      if (response.data.status === "Valid") {
        sessionStorage.setItem("ITS_ID", JSON.stringify(its));
        sessionStorage.setItem("ID", JSON.stringify(ip));
        loggedIn(true);
        navigate("/ActiveEvents");
        setSnackbarMessage("Login successful");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } else if (response.data.status === "InvalidITS") {
        setSnackbarButton(false);
        setSnackbarMessage("Invalid ITS number");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      } else if (response.data.status === "AlreadyLoggedIn") {
        setSnackbarButton(true);
        setSnackbarMessage("User is already logged in");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      } else {
        setSnackbarButton(false);
        setSnackbarMessage("Invalid ITS number or already logged in.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Login error:", error);
      setSnackbarMessage(
        "An error occurred during login. Please try again later."
      );
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      // Reset authenticating status
      setAuthenticating(false);
    }
  };

  const handleResetLogin = async (event) => {
    // Perform reset login logic here
    const its = id;
    try {
      // Call your UpdateLogin.php API to reset the login
      const response = await axios.post("/UpdateLogin.php", { Id: its });
      if (response.data.status === "Valid") {
        setSnackbarButton(false);
        setSnackbarMessage("Login reset successfully. Try Logging in now!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } else {
        setSnackbarButton(false);
        setSnackbarMessage("Failed to reset login. Please try again later.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Reset login error:", error);
    }
  };

  return (
    <>
      <section className="login-main-wrapper">
        <div className="container-fluid pl-0 pr-0">
          <div className="row no-gutters">
            <div className="col-md-12 p-5 py-5 bg-white full-height">
              <div className="login-main-left">
                <div className="text-center mb-5 login-main-left-header pt-4">
                  <img
                    src="/Assets/img/Logo_Bhopal_Jamaat.png"
                    className="img-fluid"
                    alt="LOGO"
                    style={{ marginTop: "-50px", height: "50%", width: "50%" }}
                  />
                  <h5 className="mt-3 mb-3">
                    Welcome to Haidery Masjid - Bhopal
                  </h5>
                  <p>
                    A platform for live majlis streaming and live events can be
                    accessed for Haidery Masjid - Bhopal.
                  </p>
                </div>
                <form onSubmit={handleLogin}>
                  <div className="form-group">
                    <label>ITS ID</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter ITS ID"
                      required={true}
                      name="ITS"
                      value={id}
                      onChange={(e) => setId(e.target.value)}
                    />
                  </div>

                  <div className="mt-4">
                    <div className="row">
                      <div className="col-12">
                        <button
                          type="submit"
                          className="btn btn-outline-info btn-block btn-lg"
                          disabled={!id || authenticating}
                        >
                          {authenticating
                            ? "Authenticating..."
                            : id
                            ? "Login"
                            : "Please Enter ITS ID"}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={8000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          action={
            snackbarButton === true ? (
              <Button
                color="inherit"
                size="small"
                onClick={handleResetLogin}
                style={{ fontWeight: "bold" }}
              >
                Click here to Reset Login
              </Button>
            ) : (
              ""
            )
          }
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}
