import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
import Events from "./Events";
import Login from "./Login";
import Header from "./Header";
import SingleVideo from "./SingleVideo";
import Footer from "./Footer";

const Container = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [loggedIn, setLoggedIn] = useState(false); // Track login status
  const [loggedOut, setLoggedOut] = useState(false); // Track logout status

  const navigate = useNavigate();

  useEffect(() => {
    checkLoginStatus();
  }, []);

  useEffect(() => {
    if (loggedOut) {
      handleLogoutEffect();
    }
  }, [loggedOut]);

  const checkLoginStatus = async () => {
    const its = JSON.parse(sessionStorage.getItem("ITS_ID"));
    const ip = JSON.parse(sessionStorage.getItem("ID"));
    if (its) {
      try {
        const response = await axios.post("/CheckLoginStatus.php", {
          Id: its,
          IP: ip,
        });
        if (response.data.status === "Valid") {
          setLoggedIn(true);
        } else if (
          response.data.status === "LoginExpired" ||
          response.data.status === "Invalid"
        ) {
          const responseSession = await axios.post("/UpdateLogin.php", {
            Id: its,
          });
          if (responseSession.data.status === "Valid") {
            sessionStorage.removeItem("ITS_ID");
            sessionStorage.removeItem("ID");
            setLoggedIn(false);
            setSnackbarMessage("Session Expired! Kindly login again");
            setSnackbarSeverity("warning");
            setSnackbarOpen(true);
          }
        }
      } catch (error) {
        console.error("Error checking login status:", error);
      }
    }
  };

  const handleLogoutEffect = () => {
    setLoggedIn(false);
    setSnackbarMessage("User Logged out!!");
    setSnackbarSeverity("success");
    setSnackbarOpen(true);
    setLoggedOut(false); // Reset loggedOut state

    // Navigate to login page
    navigate("/", {
      state: { message: "User Logged out!!", severity: "success" },
    });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            loggedIn ? (
              <Navigate to="/ActiveEvents" />
            ) : (
              <>
                <Login loggedIn={setLoggedIn} status={loggedIn} />
                <Footer color={"grey"} bgcolor={"white"} />
              </>
            )
          }
        />
        {/* Events page accessible only if logged in */}
        <Route
          path="/ActiveEvents"
          element={
            loggedIn ? (
              <>
                <Header logout={setLoggedOut} />
                <Events /> <Footer position={"fixed"} />
              </>
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/SingleVideo"
          element={
            loggedIn ? (
              <>
                <Header logout={setLoggedOut} />
                <SingleVideo />
                <Footer />
              </>
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route path="*" element={<p>Page not Found !!</p>} />
      </Routes>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default Container;
