import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";

export default function SingleVideo() {
  const [link, setLink] = useState(null);
  const [userDetail, setUserDetails] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const query = new URLSearchParams(window.location.search);
      const eventId = query.get("EventId");
      const videoType = query.get("VideoType");

      if (eventId && videoType) {
        try {
          const response = await axios.post("/VideoDetails.php", {
            Id: eventId,
            Type: videoType,
          });
          const { status, Link } = response.data.data;

          if (status === "invalid") {
            showError("Something went wrong! Event does not exist");
          } else if (status === "valid") {
            setLink(Link);
          } else {
            showError("Something went wrong & Data not found!");
          }
        } catch (error) {
          console.error("Error fetching video details:", error);
          showError("An error occurred while fetching video details");
        }
      }

      try {
        const its = JSON.parse(sessionStorage.getItem("ITS_ID"));
        const response = await axios.post("/UserDetails.php", { ITS: its });
        setUserDetails(response.data.phpresult);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchData();
  }, []);

  const showError = (message) => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: message,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.replace("/ActiveEvents");
      }
    });
  };

  const ScreenHandler = () => {
    const iframe = document.querySelector("iframe");
    if (iframe) {
      if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
      } else if (iframe.webkitRequestFullscreen) {
        iframe.webkitRequestFullscreen();
      } else if (iframe.msRequestFullscreen) {
        iframe.msRequestFullscreen();
      }
    } else {
      console.error("Iframe element not found");
    }
  };

  return (
    <div id="wrapper">
      <div id="content-wrapper">
        <div className="container-fluid pb-0">
          <div className="top-category section-padding mb-4">
            <div className="row">
              <div className="col-md-12">
                <div className="main-title">
                  <div
                    className="btn-group float-right right-action"
                    style={{ color: "white", fontWeight: "bolder" }}
                  >
                    Name: {userDetail?.Name || ""}
                    <br />
                    Area/Jamaat: {userDetail?.Area || ""}
                    <br />
                  </div>
                  <h6 style={{ color: "white" }}>Haidery Masjid - Bhopal</h6>
                </div>
              </div>
            </div>
          </div>
          {/* <hr /> */}
          <div className="video-block section-padding">
            <div className="row">
              <div className="col-xl-12">
                {/* <p
                  style={{
                    fontWeight: "bolder",
                    color: "white",
                    fontSize: "16px",
                  }}
                >
                  Note: If video isn't playing, please refresh the page or try
                  reloading the event. Remember to log out after the event is
                  completed.
                </p> */}
                <div className="text-center mb-3">
                  <button onClick={ScreenHandler} className="btn btn-danger">
                    Open Video in Fullscreen
                  </button>
                </div>
              </div>
              {link && (
                <div className="col-xl-12 col-sm-12 mb-3">
                  <div className="video-container Pointer">
                    <iframe
                      id="YT"
                      width="100%"
                      height="450"
                      src={`https://www.youtube.com/embed/${link}?autoplay=1&rel=0&modestbranding=1&controls=1&disablekb=1&fs=1`}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="Haidery Masjid Bhopal"
                    />
                  </div>
                </div>
              )}
              {!link && (
                <div className="col-xl-12 col-sm-12 mb-3">
                  <div className="alert alert-danger" role="alert">
                    Unable to load video. Please try again later.
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
