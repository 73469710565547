import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Container from "./Components/Container";
import DisableRightClick from "./Components/disableRightClick"; // Import your HOC

function App() {
  return (
    <Router>
      <DisableRightClick>
        <Container />
      </DisableRightClick>
    </Router>
    // <Router>
    //   <Container />
    // </Router>
  );
}

export default App;
