import React, { useEffect } from "react";

const DisableRightClick = ({ children }) => {
  useEffect(() => {
    const disableContextMenu = (e) => {
      e.preventDefault();
    };

    // Add event listener when component mounts
    document.addEventListener("contextmenu", disableContextMenu);

    // Clean up the event listener when component unmounts
    return () => {
      document.removeEventListener("contextmenu", disableContextMenu);
    };
  }, []);

  return <>{children}</>;
};

export default DisableRightClick;
