import React from "react";

const Footer = ({ color, bgcolor, position }) => {
  return (
    <div>
      <footer style={{ backgroundColor: bgcolor, position: position }}>
        <p style={{ color: color }}>
          Designed & Developed by Husain. For collaboration, contact at
          +919589069980
        </p>
      </footer>
    </div>
  );
};

export default Footer;
